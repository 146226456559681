import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Obfuscate from "react-obfuscate";

import CommonLogo from "./UI/CommonLogo";

const Home = (props) => {

  const location = useLocation()

  let marginTop = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "mt-3" : "mt-3";
  let paddingTop = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "pt-3" : "pt-3";
  let marginTopContact = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "mt-5" : "mt-5";
  let paddingTopContact = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "pt-5" : "pt-5";
  let tagsSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "are-medium" : "are-large";

  return (
    <motion.section
      className="hero is-fullheight"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}

    >
      <div className="hero-head py-3 has-text-centered">
        <CommonLogo />
      </div>
      <div className="hero-body">
        <div className="container has-text-centered">

          <p className="is-size-2-touch is-size-1-desktop has-text-ps-light px-5">
            ¿ Deseas <u>ahorrar dinero</u> en tus compras de primera necesidad ?
          </p>
          <div className={`columns is-centered is-mobile ${marginTop} ${paddingTop}`}>
            <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-fullhd">
              <div className="is-flex is-align-items-center is-justify-content-space-between">
                <div style={{ display: "flex", width: "100%" }} onClick={() => props.setDirection(1)}>
                  <Link to="/menu" className="button is-fullwidth is-rounded mx-3 is-pp is-inverted has-text-weight-semibold is-size-5-touch is-size-4-desktop" >
                    SI
                  </Link>
                </div>
                {
                  location.pathname !== '/menu' && location.pathname !== '/aun-no-interesado' &&
                  <div style={{ display: "flex", width: "100%" }} onClick={() => props.setDirectionY(1)}>
                    <Link to={`/aun-no-interesado`} className="button is-rounded is-fullwidth mx-3 has-text-weight-bold is-pp is-inverted is-outlined is-size-5-touch is-size-4-desktop">
                      NO
                    </Link>
                  </div>
                }
              </div>
              <div className={`has-text-centered has-text-pp-light is-size-7-touch is-size-6-desktop ${marginTopContact} ${paddingTopContact}`}>.::CONOZCÁMONOS::.</div>
              <div className="is-flex is-justify-content-center">
                <div className={`tags has-addons ${tagsSize}`}>
                  <div className="tag">
                    <div className={`icon has-text-pp`}>
                      <FontAwesomeIcon icon={faEnvelope} size="1x" />
                    </div>
                  </div>
                  <div className="tag has-text-pp">
                    <Obfuscate
                      email="contacto@pmatch.cl"
                      headers={{
                        subject: 'Contacto desde www.pmatch.cl',
                      }}
                      className="has-text-pp"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-footer py-3">
        <div className="has-text-centered has-text-pp-light">
          <b>PMATCH</b> por TENAZ Applied Data Studio
        </div>
      </div>
    </motion.section>
  );

};

export default Home;
