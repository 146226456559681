import { useState, lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "./components/Home";
import Loader from "./components/UI/Loader";
import Background from "./components/UI/Background";
import useWindowSize from "./components/UI/ScreenSize";

const Menu = lazy(() => import("./components/Menu"));
const MenuOption1 = lazy(() => import("./components/MenuOption1"));
const MenuOption2 = lazy(() => import("./components/MenuOption2"));
const MenuOption21 = lazy(() => import("./components/MenuOption21"));
const MenuOption3 = lazy(() => import("./components/MenuOption3"));
const MenuOption4 = lazy(() => import("./components/MenuOption4"));
const MenuOption5 = lazy(() => import("./components/MenuOption5"));
const MenuOption6 = lazy(() => import("./components/MenuOption6"));
const MenuOption7 = lazy(() => import("./components/MenuOption7"));
const MenuOption8 = lazy(() => import("./components/MenuOption8"));
const NotInterested = lazy(() => import("./components/NotInterested"));

const NotFound = lazy(() => import("./components/NotFound"));

function App() {

  const location = useLocation()
  const windowSize = useWindowSize()

  const [apiProducts, setApiProducts] = useState({});
  const [apiRoles, setApiRoles] = useState({});
  const [apiBestDiscounts, setApiBestDiscounts] = useState([])
  const [apiBestOffers, setApiBestOffers] = useState([])
  const [apiMarketsComparisions, setApiMarketsComparisions] = useState([])


  const [direction, setDirection] = useState(1);
  const [directionY, setDirectionY] = useState(1);

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? "100vw" : "-100vw",
        opacity: 0
      };
    },
    animate: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? "100vw" : "-100vw",
        opacity: 0
      };
    }
  };

  const variantsY = {
    enter: (directionY) => {
      return {
        y: directionY > 0 ? "100vh" : "-100vh",
        opacity: 0
      };
    },
    animate: {
      zIndex: 1,
      y: 0,
      opacity: 1
    },
    exit: (directionY) => {
      return {
        zIndex: 0,
        y: directionY < 0 ? "100vh" : "-100vh",
        opacity: 0
      };
    }
  };

  const setDirectionHandler = (direction) => {
    setDirection(prevState => {
      return prevState > 0 ? direction : direction
    })
  }

  const setDirectionYHandler = (directionY) => {
    setDirectionY(prevState => {
      return prevState > 0 ? directionY : directionY
    })
  }

  return (
    <AnimatePresence mode="wait" custom={direction}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Background />} err>
          <Route index={true} path="/" element={<Home setDirection={setDirectionHandler} setDirectionY={setDirectionYHandler} windowSize={windowSize} />} />
          <Route path="/menu" element={<Suspense fallback={<Loader />}><Menu variants={variants} direction={direction} setDirection={setDirectionHandler} /></Suspense>} />
          <Route path="/que-es-pmatch" element={<Suspense fallback={<Loader />}><MenuOption1 variants={variants} direction={direction} setDirection={setDirectionHandler} windowSize={windowSize} /></Suspense>} />
          <Route path="/que-encontraras-hoy" element={<Suspense fallback={<Loader />}><MenuOption2 variants={variants} direction={direction} setDirection={setDirectionHandler} apiProducts={apiProducts} setApiProducts={setApiProducts} windowSize={windowSize} /></Suspense>} />
          <Route path="/clasificacion-productos" element={<Suspense fallback={<Loader />}><MenuOption21 variants={variants} direction={direction} setDirection={setDirectionHandler} apiProducts={apiProducts} setApiProducts={setApiProducts} windowSize={windowSize} /></Suspense>} />
          <Route path="/como-lo-hacemos" element={<Suspense fallback={<Loader />}><MenuOption3 variants={variants} direction={direction} setDirection={setDirectionHandler} windowSize={windowSize} /></Suspense>} />
          <Route path="/que-te-ofrecemos" element={<Suspense fallback={<Loader />}><MenuOption4 variants={variants} direction={direction} setDirection={setDirectionHandler} windowSize={windowSize} /></Suspense>} />
          <Route path="/que-te-aseguramos" element={<Suspense fallback={<Loader />}><MenuOption5 variants={variants} direction={direction} setDirection={setDirectionHandler} windowSize={windowSize} /></Suspense>} />
          <Route path="/cuanto-te-costara" element={<Suspense fallback={<Loader />}><MenuOption6 variants={variants} direction={direction} setDirection={setDirectionHandler} apiRoles={apiRoles} setApiRoles={setApiRoles} windowSize={windowSize} /></Suspense>} />
          <Route path="/que-mas-obtendras" element={<Suspense fallback={<Loader />}><MenuOption7 variants={variants} direction={direction} setDirection={setDirectionHandler} windowSize={windowSize} /></Suspense>} />
          <Route path="/donde-comenzar" element={
            <Suspense fallback={<Loader />}>
              <MenuOption8
                variants={variants}
                direction={direction}
                setDirection={setDirectionHandler}
                windowSize={windowSize}
                apiBestDiscounts={apiBestDiscounts}
                setApiBestDiscounts={setApiBestDiscounts}
                apiBestOffers={apiBestOffers}
                setApiBestOffers={setApiBestOffers}
                apiMarketsComparisions={apiMarketsComparisions}
                setApiMarketsComparisions={setApiMarketsComparisions}
              />
            </Suspense>} />
          <Route path="/aun-no-interesado" element={
            <Suspense fallback={<Loader />}>
              <NotInterested
                variants={variantsY}
                direction={directionY}
                setDirection={setDirectionYHandler}
                setDirectionX={setDirectionHandler}
                windowSize={windowSize}
                apiBestDiscounts={apiBestDiscounts}
                setApiBestDiscounts={setApiBestDiscounts}
                apiBestOffers={apiBestOffers}
                setApiBestOffers={setApiBestOffers}
                apiMarketsComparisions={apiMarketsComparisions}
                setApiMarketsComparisions={setApiMarketsComparisions}
              />
            </Suspense>} />
          <Route path="/*" element={<Suspense fallback={<Loader />}><NotFound /></Suspense>} />
        </Route>
      </Routes>
    </AnimatePresence>
  )

};

export default App;