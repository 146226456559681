const CommonLogo = () => {
    return (
        <div className="is-flex is-justify-content-center px-5 mt-4">
        <div style={{maxWidth: "320px", width: "100%"}}>
            <figure className="image is-5by1">
                <img alt="icon" src={`${process.env.PUBLIC_URL + '/icons/pmatch-logo@2x.png'}`} />
            </figure>
        </div>
        </div>
    )
};

export default CommonLogo;