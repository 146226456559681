import { Outlet, useLocation } from "react-router-dom";

import CommonLogo from "./CommonLogo";

const Background = (props) => {

    const location = useLocation();

    return (

        <div
            style={{
                backgroundImage: `linear-gradient(rgb(17, 2, 53, 0.64), rgb(17, 2, 53, 0.64)), url(${process.env.PUBLIC_URL + '/background.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                height: '100vh',
                overflowY: 'auto'
            }}
        >
            <div className="columns is-centered is-gapless">
                <div className="column is-full-mobile is-three-quarters-tablet is-two-thirds-desktop">
            {
                location.pathname !== "/" && location.pathname !== "/donde-comenzar"  && location.pathname !== "/aun-no-interesado" &&
                <div className="py-3">
                    <CommonLogo />
                </div>
            }
            <Outlet />
            </div>
            </div>
        </div>
    )
};

export default Background;